/**
 * 
 * @param el 
 */
function toggleSharingIcons(el: Event) {

    //
    const element = (el.currentTarget as HTMLElement);

    //
    element.classList.toggle('show')
    

}

function toggleSharingBox(id: number) {
    elementToggle('appPopUpSharing', 110)
}
