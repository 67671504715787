/**
 * 
 */
function payNow(id?: string, alertId?: string): void {

    // init
    const payBox = document.getElementById(id ? id : 'payBox');

    //
    payBox?.classList.toggle('show')
    popUpPay?.classList.add('hasAlertMsg')
    elementToggle(alertId ? alertId : 'alertMsg', 120)

}

/**
 * 
 * @param ev 
 */
function selectPaymentMethod(ev: Event): void {

    /**
     * init
     */
    const li = (ev.currentTarget as HTMLLIElement);
    const btns = document.querySelectorAll<HTMLButtonElement>(`[data-payment-type]`);

    // ES6 Destruction
    const { parentElement } = li;

    /**
     * 
     */
    if (parentElement?.children instanceof HTMLCollection && parentElement?.children.length > 0) {

        /**
         * remove active class from all children
         */
        Array.from(parentElement?.children).forEach(li => li.classList.remove('active'))
        /**
         * then adding it in current child
         */
        li.classList.add('active');

        /**
         * show right btn
         */
        Array.from(btns).forEach(btn => {
            
            btn.classList.add('d-none')
            
            if (btn.getAttribute(`data-payment-type`) == li.id) {
                if (btn.disabled) btn.disabled = false;
                btn.classList.remove('d-none')
            }
            
        })

    }


}

/**
 * 
 */
function hideAlertBox(alertId?: string): void {
    const el = document.getElementById(alertId ? alertId : 'alertMsg');
    el?.classList.remove('show')
    popUpPay?.classList.remove('hasAlertMsg')
}

/**
 * 
 * @param ev 
 */
function handlerFastDonateType(ev: Event): void {

    /**
     * init
     */
    const li = (ev.target as HTMLLIElement);
    const description = document.getElementById('fastDonateTypeDes') as HTMLHeadingElement

    // ES6 Destruction
    const { children } = (document.getElementById('fastDonateTypes') as HTMLUListElement);

    /**
     * 
     */
    if (children instanceof HTMLCollection && children.length > 0) {

        /**
         * remove active class from all children
         */
        Array.from(children).forEach(li => li.classList.remove('active'))
        /**
         * then adding it in current child
         */
        li.classList.add('active')
        /**
         * then get data-type-description fro child to set it in heading
         */
        description.textContent = li.getAttribute('data-type-description')

    }

}

/**
 * 
 */
function toggleFastDonate(show: boolean) {

    if (show) {
        /**
         * 
         */
        fastDonate?.classList.add('show')
        if (fastDonate?.classList.contains('collapsed') == true) fastDonate?.classList.remove('collapsed')

        /**
         * show FastDonateTypes
         * and hide fastDonateTitle
         */
        warpFastDonateTypes.classList.remove('d-none');
        fastDonateTitle.classList.add('d-none')
    
        /**
         * 
         */
        toggleDonateDetails(true)
        handlerTogglePopUpPay()

    }
    else fastDonate?.classList.remove('show')
    
}

/**
 * 
 */
function handlerFastDonatePaymentBox() {
    togglePaymentBox(true)
    toggleDonateDetails(false)
}

/**
 * 
 */
function handlerDonateNow(ev: Event) {

    
    /**
     * 
     */
    const el = ev.currentTarget as HTMLElement;

    /**
     * hide FastDonateTypes from donate Details
     */
    warpFastDonateTypes.classList.add('d-none');

    /**
     * show fastDonateTitle
     */
    fastDonateTitle.classList.remove('d-none')
    fastDonateTitle.textContent = el.getAttribute('data-title-payment')
    
    toggleDonateDetails(true)
    handlerTogglePopUpPay();

}

/**
 * 
 */
function toggleDonateDetails(show: boolean): void {

    /**
     * 
     */
    const el = document.getElementById('donateDetails');

    if (show) el?.classList.add('show')
    else el?.classList.remove('show')
}


/**
 * 
 */
function togglePaymentBox(show: boolean, payBoxId?: string): void {

    /**
     * 
     */
    const el = document.getElementById(payBoxId ? payBoxId : 'payBox');

    if (show) el?.classList.add('show')
    else el?.classList.remove('show')
}

/**
 * 
 */
function hideContainerPayment(containerId?: string, alertId?: string): void {
    /**
     * hide all children
     */
    hideAlertBox(alertId);
    togglePaymentBox(false)
    toggleDonateDetails(false);
    toggleFastDonate(false)
    handlerTogglePopUpPay(containerId)
}

/**
 * 
 */
function showContainerPayment(): void {
    togglePaymentBox(true);
    handlerTogglePopUpPay()
}

/**
 * 
 */
function handlerTogglePopUpPay(id?: string) {

    /**
     * toggle fade element
     */
    elementToggle(id ? id : 'popUpPay', 120).then(res => {

        /**
         * toggle stop or run scroll bar
         */
        if (res) document.body.classList.add('overflow-hidden')
        else document.body.classList.remove('overflow-hidden')

    })
   
}

/**
 * 
 * @param el 
 */
function toggleGiftDonationBox(el: Event) {

    //
    const input = (el.currentTarget as HTMLInputElement);
    const giftDonationBox = document.getElementById('giftDonationBox');

    //
    if (input.checked) giftDonationBox?.classList.add('d-block')
    else giftDonationBox?.classList.remove('d-block')

} 

/**
 * 
 * @param cardNumber 
 * @returns 
 */
function formatCreditCardNumber(ev: Event) {

    const el = ev.target as HTMLInputElement;
    
    const cardNumber: string = el.value;

    // Remove any non-digit characters from the input
    const cleanedNumber = cardNumber.replace(/\D/g, '');
  
    // Use a regular expression to add a space every four digits
    const formattedNumber = cleanedNumber.replace(/(\d{4})(?=\d)/g, '$1 ');
  
    //
    el.value = formattedNumber;
    
}
