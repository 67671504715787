let fileName = '';

/**
 * 
 * @param ev 
 * @param idFilePlaceholder element that will show file name
 */
function fileUpload(ev: Event, idFilePlaceholder: string) {

    //
    const input = (ev.target as HTMLInputElement);
    const filePlaceholder = document.getElementById(idFilePlaceholder);

    //
    if (input.files != null && input.files.length > 0) {

        //
        const file = input.files.item(0);

        //
        if (filePlaceholder != null) {
            
            // save default placeholder
            if(fileName == '') fileName = filePlaceholder.textContent || '';

            //
            filePlaceholder.textContent = file?.name || ''
        }
    }
    else if (filePlaceholder != null) filePlaceholder.textContent = fileName

}