/**
 * 
 * @param ev Event 
 * @returns 
 */
function stopPropagation(ev: Event) {

    if (ev instanceof Event) {
        ev.stopPropagation()
        return null
    }

    throw new Error('event is not undefined')

}

/**
 * 
 * @param elementId id Element
 * @param fadeOut daily mile Seconds  to remove class show
 */
async function elementToggle(elementId: string, fadeOut: number = 300) {
    
    // get
    const el = document.getElementById(elementId);

    //
    if (el == null) throw new Error('Element id is null');


    /**
     * 
     */
    return new Promise<boolean>((res) => {

        //
        if (!el.classList.contains('show')) {
            
            //
            el.classList.add('show');
            setTimeout(() => {
        
                el.classList.add('animation')
                res(true)
            }, 1)
        }
        else {
            el.classList.remove('animation')
            const clear = setTimeout(() => {
        
                el.classList.remove('show')
                res(false)
            }, fadeOut)        
        }    

    })



}


/**
 * 
 * @param value text for copy
 * @returns promise will return string or Exception
 */
async function ClipboardHandler(value: string): Promise<string | unknown>{

    const { clipboard } = navigator || {}
    
    if (clipboard) {
        
        try {

            await clipboard.writeText(value)
            const read =  await clipboard.readText();

            return read

        } catch(err) {
            throw err
        }

    }
    else alert("Your Browser Need Update Because it's older")

}


function copyUrl() {

    //
    const url = document.getElementById('url');
    const copiedAlert = document.getElementById('copiedAlert');

    //
    if (url?.textContent) {

        //
        ClipboardHandler(url.textContent.trim())
        .then(res => {

            // copied
            copiedAlert?.classList.add('d-flex')

            //
            const clear = setTimeout(() => copiedAlert?.classList.remove('d-flex'), 400);

        })

    }

}
